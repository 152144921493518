import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, RenderInput } from "@shared/components/Form";
import { number, object } from "yup";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { selectCommon } from "../store/slices/common";
import { deletePayment } from "../store/slices/payments";
// import { undoPayment } from "../store/slices/loans";
import { useDispatch, useSelector } from "react-redux";
import { createQueue } from "../store/slices/syncronization";
import { deleteNewLoan, modifyNewLoan } from "../store/slices/newLoans";
import { unLockCuadre } from "../store/slices/common";
import { verifyAccessCode } from "@shared/api";

const AccessCodes = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  let awaitingAction = localStorage.getItem("awaitingAction");
  awaitingAction = awaitingAction ? JSON.parse(awaitingAction) : null;

  if (!awaitingAction) return <Redirect to="/dashboard" />;

  const common = useSelector(selectCommon);

  const initialValues = {
    accessCode: "",
    type: awaitingAction.type,
    owner_id: common.owner_id,
  };

  const validation = object({
    accessCode: number().required("Codigo es requerida!"),
  });

  let submithandler = null;
  switch (awaitingAction.type) {
    case "deletePayment":
      submithandler = async (data) => {
        try {
          await verifyAccessCode(data);

          const { syncProps, postData, payment } = awaitingAction;

          // if (payment.payoffLoanId) {
          //   dispatch(updatePayoff(payment));
          // }

          dispatch(deletePayment(payment));
          // dispatch(undoPayment(applyLoan));

          dispatch(createQueue({ postData, syncProps }));

          history.replace("/collected");
          alert.error("Pago Borrado!");
        } catch (error) {}
      };
      break;
    case "deleteNewLoan":
      submithandler = async (data) => {
        try {
          await verifyAccessCode(data);

          const { currentLoan, newLoanPostData, newLoanSyncProps, payment } = awaitingAction;

          if (payment) {
            const { paymentSyncProps, paymentPostData, payment, applyLoan } = awaitingAction;

            dispatch(deletePayment(payment));
            // dispatch(undoPayment(applyLoan));
            dispatch(createQueue({ postData: paymentPostData, syncProps: paymentSyncProps }));

            alert.error("Pago Borrado!");
          }

          dispatch(deleteNewLoan(currentLoan));
          dispatch(createQueue({ postData: newLoanPostData, syncProps: newLoanSyncProps }));

          history.replace("/entregas");
          alert.error("Entrega Borrada!");
        } catch (error) {}
      };
      break;
    case "modifyNewLoan":
      submithandler = async (data) => {
        try {
          await verifyAccessCode(data);

          const { postData, syncProps } = awaitingAction;

          //dispatch(removeNonUniqueType(syncProps));
          dispatch(modifyNewLoan(postData));
          dispatch(createQueue({ postData, syncProps }));

          history.replace("/entregas");
          alert.info("Entrega Modificada!");
        } catch (error) {}
      };
      break;
    case "unLockCuadre":
      submithandler = async (data) => {
        try {
          await verifyAccessCode(data);

          dispatch(unLockCuadre());

          history.replace("/cuadre");
          alert.info("Cuadre desbloqueado!");
        } catch (error) {}
      };
      break;
    default:
      console.log(`Sorry, nothing match the switch Cases: ${awaitingAction.type}`);
  }

  const button = { label: "Verificar Codigo", color: "primary", variant: "contained" };

  return (
    <div className="container mt-5">
      <Typography variant="h5" gutterBottom>
        No autorizado!
      </Typography>

      <Typography variant="body1" paragraph>
        Para ejercer la siguiente acci&oacute;n necesita un codigo de acceso.
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>
          {RenderInput("accessCode", "Codigo de cceso", "number", false, "outlined", "Ingrese el codigo de acceso")}
        </Box>
      </Form>

      <Typography className="mt-2" variant="body2" paragraph>
        <b>Cada codigo de aceso solo se puede usar una vez!</b>
      </Typography>
    </div>
  );
};

export default AccessCodes;
