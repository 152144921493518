import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/slices/auth";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const auth = useSelector(selectAuth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.jwt !== null) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
