import Box from "@nodes/@material-ui/core/Box";
import Container from "@nodes/@material-ui/core/Container";
import CreateOldReferral from "@shared/components/CreateOldReferral";
import RenderReferrals from "@shared/components/RenderReferrals";
import RenderReferralsInvitations from "@shared/components/RenderReferralsInvitations";
import RenderReferralBalance from "@shared/components/RenderReferralBalance";
import CreateReferralModal from "@shared/components/CreateReferralModal";
import Loading from "@shared/components/Loading";
import { UseReferralsPending, UseReferralsAccepted } from "@shared/server/fetcher";
import { useSWRConfig } from "@nodes/swr";
import { selectCommon } from "../store/slices/common";
import { useSelector } from "react-redux";
import { createReferralLink, createOldReferral } from "@shared/api";

const Referrals = () => {
  const { mutate: globalMutate } = useSWRConfig();
  const common = useSelector(selectCommon);

  const { data: referralsAccepted, isLoading: isLoadingAccepted, key } = UseReferralsAccepted(common.user_id);
  const { data: referralsPending, isLoading: isLoadingPending, mutate } = UseReferralsPending(common.user_id);

  const handleCreateReferral = async (data) => {
    try {
      const newReferral = await createReferralLink({ ...data, user_id: common.user_id });
      mutate([...referralsPending, newReferral]);
      return newReferral;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateOldReferral = async (data) => {
    try {
      await createOldReferral(data);
      globalMutate(key);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-4 mb-5">
      <Container maxWidth="lg" disableGutters>
        <Box component="div" p={1}>
          <RenderReferralBalance referrals={referralsAccepted?.referrals} balance={referralsAccepted?.balance} />
        </Box>

        <Container maxWidth="xs">
          <CreateReferralModal callBack={handleCreateReferral} />
        </Container>

        {isLoadingPending ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderReferralsInvitations invitations={referralsPending} />
          </Box>
        )}

        {isLoadingAccepted ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderReferrals referrals={referralsAccepted.referrals} />
          </Box>
        )}

        <Box component="div" p={1} className="mt-3">
          <CreateOldReferral handleCreateReferral={handleCreateOldReferral} user_id={common.user_id} />
        </Box>
      </Container>
    </div>
  );
};

export default Referrals;
