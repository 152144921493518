import { useState, useEffect } from "react";
import PendingDataWarning from "@shared/components/PendingDataWarning";
import AppUpdateModal from "@shared/components/AppUpdateModal";
import Container from "@material-ui/core/Container";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Redirect, Link } from "react-router-dom";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import * as mobileDetect from "react-device-detect";
import RenderLoginForm from "@shared/components/RenderLoginForm";
// import OldDomainWarning from "@shared/components/OldDomainWarning";
import { useDispatch, useSelector } from "react-redux";
import { authThunk, resetAuth } from "../store/slices/auth";
import { selectAuth } from "../store/slices/auth";
import { setDevice } from "../store/slices/miscData";
import { selectQueues } from "../store/slices/syncronization";
import { setDisplayNav } from "../store/slices/ui";

const Login = () => {
  const auth = useSelector(selectAuth);
  const queues = useSelector(selectQueues);
  const dispatch = useDispatch();
  const [appUpdate, setAppUpdate] = useState(false);
  const { browserName, fullBrowserVersion, mobileVendor, mobileModel, osVersion, getUA } = mobileDetect;
  const filteredQueues = queues.filter((q) => q.requiredData === true);

  const getDeviceSpecs = () => {
    let displayMode = "Browser";
    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "PWA Standalone";
    }

    return {
      displayMode,
      appVersion: import.meta.env.VITE_VERSION,
      foceAppUpdate: import.meta.env.VITE_foceAppUpdateNumber,
      browserName,
      fullBrowserVersion,
      mobileVendor,
      mobileModel,
      osVersion,
      getUA,
    };
  };

  useEffect(() => {
    dispatch(resetAuth());
    dispatch(setDisplayNav({ DisplayNav: false }));
    dispatch(setDevice(getDeviceSpecs()));
  }, []);

  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     dispatch(resetAuth());
  //   }, 5000);

  //   return () => clearTimeout(interval);
  // }, [auth.error]);

  if (auth.status === "loading" || auth.commonStatus === "loading") return <FullLoadingScreen />;
  if (auth.customer_id) return <Redirect to={`/customer/disabled/${auth.customer_id}`} />;
  if (auth.jwt && auth.commonStatus === "succceed") return <Redirect to="/swr/fetcher" />;

  const submithandler = async (data) => dispatch(authThunk({ data }));

  return (
    // <OldDomainWarning url="https://beta-cobro.wprestamos.com" label="beta-cobro.wprestamos.com" />
    <Container fixed maxWidth="sm">
      {appUpdate && <AppUpdateModal />}

      {auth.error && (
        <div className="alert alert-danger mt-3">
          <Typography variant="subtitle2" component="p">
            {auth.error}
          </Typography>
        </div>
      )}

      {auth.commonError && (
        <div className="alert alert-danger mt-3">
          <Typography variant="subtitle2" component="p">
            {auth.commonError}
          </Typography>
        </div>
      )}

      {filteredQueues.length > 0 ? (
        <PendingDataWarning pendingData={filteredQueues} />
      ) : (
        <RenderLoginForm submithandler={submithandler} username={auth.username} />
      )}

      {/* <div style={{ marginTop: 160, marginBottom: 50 }} className="text-center">
        <Link to="/clear/app/data" className="h6">
          <span className="text-danger">Reiniciar Aplicaci&oacute;n (Borrar Todo)</span>
        </Link>
      </div> */}
    </Container>
  );
};

export default Login;
