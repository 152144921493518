import Box from "@nodes/@material-ui/core/Box";
import Button from "@nodes/@material-ui/core/Button";
import { Redirect } from "@nodes/react-router-dom";
import PhoneVerify from "@shared/components/PhoneVerify";
import { useSelector } from "react-redux";
import { selectUserLookup } from "../store/slices/tempState";
import backend from "@shared/server/backend";
import { getLocalToken } from "@shared/functions";
import Axios from "axios";

const phoneVerificationPwReset = async (data, callback) => {
  let token = getLocalToken();
  try {
    const client = await Axios.get("https://ipapi.co/json/");
    data.ipAddress = client.data.ip;
    data.token = token;

    const { data: res } = await backend.post("/phone/verification/pw/reset", { data });
    callback(`/password/new/${res.user_id}/${res.salt}/${res.expTimestamp}`);
  } catch (err) {
    console.log("AppWorkflow - phoneVerificationPwReset from dataContext - Catch error");
    console.log(err.message);
  }
};

const UserPhoneVerify = ({ history }) => {
  const passwordReset = useSelector(selectUserLookup);

  if (Object.keys(passwordReset).length === 0) return <Redirect to="/" />;

  const submithandler = (data) => phoneVerificationPwReset(data, history.replace);
  return (
    <div className="container mt-4 mb-5">
      <PhoneVerify submithandler={submithandler} userData={passwordReset} />

      <Box component="div" paddingTop={5}>
        <Button fullWidth variant="outlined" color="primary" onClick={() => history.replace("/password/resetmethod")}>
          Elegir metodo de verificacion!
        </Button>
      </Box>
    </div>
  );
};

export default UserPhoneVerify;
