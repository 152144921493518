import { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/slices/auth";
import jwtDecode from "jwt-decode";

const WrongCuadreWarning = () => {
  const [forceLogout, setForceLogout] = useState(false);
  const auth = useSelector(selectAuth);

  useEffect(() => {
    if (!auth.jwt) return;
    const decodedJwt = jwtDecode(auth.jwt);

    if (decodedJwt.exp < Date.now() / 1000) {
      setForceLogout(true);
    }
  }, []);

  if (forceLogout === false) return null;

  return (
    <div>
      <Box component="div" p={1} paddingBottom={2}>
        <Alert variant="outlined" severity="error">
          <Typography variant="h6" component="p" gutterBottom>
            Fecha de cuadre antiguo!
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Est&aacute; usando la lista de cobro de la semana pasada.
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Para actualizar la lista de cobro debe guardar el este Cuadre e iniciar sesi&oacute;n nuevamente.
          </Typography>
        </Alert>
      </Box>
    </div>
  );
};

export default WrongCuadreWarning;
