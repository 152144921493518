import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/slices/auth";
import { Redirect, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const Index = () => {
  const history = useHistory();
  const auth = useSelector(selectAuth);

  // function sendToQuickPrinterChrome(data = null) {
  //   console.log("Function is being called.");
  //   let commandsToPrint =
  //     data ??
  //     //BElow works
  //     "<CENTER><MEDIUM2>RECIBO DE PAGO!\r\n" +
  //       "<CENTER>17-octubre-2024 - 07 34 15pm\r\n" +
  //       "<DLINE>\r\n" +
  //       "<MEDIUM2><BOLD>INVERSIONES WILSON\r\n" +
  //       "<NORMAL>Zona Jueves\r\n" +
  //       "Cobrado por Wilson\r\n" +
  //       "(347) 812-7103\r\n" +
  //       "<LINE><RIGHT>Fecha 17-oct-24\r\n" +
  //       "<BOLD><MEDIUM2>Wilson Benavides\r\n" +
  //       "Prestamo No. P189755\r\n" +
  //       "Cantidad Prestada. $5,000\r\n" +
  //       "Ent. 28-dic-23 Ter. 28-mar-24\r\n" +
  //       "Cuota Pagada ....5/13 $500\r\n" +
  //       "Total Monto Pagado  $500\r\n" +
  //       "Atrasos Pendiente   $2,000\r\n" +
  //       "<MEDIUM1>Balance $4,000\r\n" +
  //       "<SMALL>SIN RECIBO, NO HAY RECLAMOS!\r\n" +
  //       "<SMALL>En caso de incumplimiento, sus\r\n" +
  //       "<SMALL>datos seran procesados a data\r\n" +
  //       "<SMALL>credito!<CUT>\n";

  //   try {
  //     var textEncoded = encodeURI(commandsToPrint);
  //     window.location.href =
  //       "intent //" + textEncoded + "#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;";
  //   } catch (error) {
  //     alert("failed to print");
  //   }
  // }

  if (auth.jwt) return <Redirect to="/dashboard" />;

  return (
    <Card className="mt-5 mb-5" variant="outlined">
      {/* <button onClick={() => sendToQuickPrinterChrome("_")}>Test Printer _</button>
      <br />
      <br />

      <button onClick={() => sendToQuickPrinterChrome("-")}>Test Printer -</button>
      <br />
      <br />

      <button onClick={() => sendToQuickPrinterChrome(".")}>Test Printer .</button>
      <br />
      <br />
      <button onClick={() => sendToQuickPrinterChrome(",")}>Test Printer ,</button>
      <br />
      <br />
      <button onClick={() => sendToQuickPrinterChrome(":")}>Test Printer :</button>
      <br />
      <br />

      <button onClick={() => sendToQuickPrinterChrome("#")}>Test Printer #</button>
      <br />
      <br />

      <button onClick={() => sendToQuickPrinterChrome("$")}>Test Printer $</button>
      <br />
      <br />

      <button onClick={() => sendToQuickPrinterChrome("!")}>Test Printer !</button>
      <br />
      <br />
      <button onClick={() => sendToQuickPrinterChrome()}>Test Printer Everything</button> */}

      <CardContent>
        <Typography className="mt-3" variant="h5" gutterBottom>
          Aplicacion de Cobro!
        </Typography>

        <Typography className="mt-3" variant="body1" paragraph>
          Todo los datos colectados durante el uso de esta aplicacion permancen guardados unicamente en su telefono, por
          esa razon la app puede trabajar sin internet.
        </Typography>

        <Typography className="mt-3" variant="body1" paragraph>
          La aplicacion puede trabajar sin internet, pero es recomendable conectarse al internet lo mas posible para que
          la aplicacion pueda guardar los datos en vivo.
        </Typography>

        {/* <Typography className="mt-3" variant="body1" paragraph>
          No me hago responsable por datos perdido por no cerrar Cuadre al terminar de Cobrar.
        </Typography> */}

        <Button className="mt-2" fullWidth variant="outlined" color="primary" onClick={() => history.push("/login")}>
          Iniciar Sesion
        </Button>
      </CardContent>
    </Card>
  );
};

export default Index;
