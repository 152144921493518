import axios from "@nodes/axios";
import { mainApp } from "@/config";

const instance = axios.create({ baseURL: import.meta.env.VITE_API_URL, timeout: 60000 });

instance.interceptors.request.use((req) => {
  req.headers.mainapp = mainApp;
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user")).token}`;
  }
  return req;
});

const exportDefault = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
};

export default exportDefault;
