import RenderDebtorForm from "@shared/components/RenderDebtorForm";
import { useParams } from "react-router";
import { FaUserEdit } from "react-icons/fa";
import Loading from "@shared/components/Loading";
import { selectCommon } from "../store/slices/common";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import { updateNewDebtor } from "../store/slices/newDebtors";
// import { removeNonUniqueType } from "../store/slices/syncronization";
// import { alertSuccess } from "../store/slices/snackbar";
import { useGetDebtorsQuery, useUpdateDebtorMutation } from "../store/slices/api";
import RenderBackButton from "@shared/components/RenderBackButton";
import { getUniqueID } from "@shared/functions";
import { updateDebtorInfo } from "../store/slices/loans";
import { updateDebtorNewLoan } from "../store/slices/newLoans";
import { useAlert } from "react-alert";

const DebtorEdit = () => {
  const alert = useAlert();
  const { id: dUniqueId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const common = useSelector(selectCommon);

  const [updateDebtor] = useUpdateDebtorMutation();
  const { data: debtors } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  if (!debtors) return <Loading />;

  const currentDebtor = debtors.find((x) => x.dUniqueId === dUniqueId);

  const initialValues = {
    dUniqueId,
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
    debtor_id: currentDebtor.debtor_id,
    name: currentDebtor.name ? currentDebtor.name : "",
    new_debtor: currentDebtor.new_debtor,
    cedula: currentDebtor.cedula ? currentDebtor.cedula : "",
    phone: currentDebtor.phone ? currentDebtor.phone : "",
    phone2: currentDebtor.phone2 ? currentDebtor.phone2 : "",
    nickname: currentDebtor.nickname ? currentDebtor.nickname : "",
    display_name: currentDebtor.display_name ? currentDebtor.display_name : "",
    birthdate: "",
    address: currentDebtor.address ? currentDebtor.address : "",
    work_address: currentDebtor.work_address ? currentDebtor.work_address : "",
    next_to: currentDebtor.next_to ? currentDebtor.next_to : "",
    cosigner: currentDebtor.cosigner ? currentDebtor.cosigner : "",
    note: currentDebtor.note ? currentDebtor.note : "",
    occupation: currentDebtor.occupation ? currentDebtor.occupation : "",
    auto_sms: 0,
    zone_id: currentDebtor.zone_id ? currentDebtor.zone_id : 0,
  };

  const button = {
    icon: <FaUserEdit />,
    label: "Actualizar Cliente",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (data) => {
    const debtor_id = data.new_debtor !== true ? data.debtor_id : "";
    const debtorSearch = `${debtor_id} ${data.name} ${data.nickname} ${data.cedula} ${data.phone} ${data.phone2}`;

    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`;
    const syncProps = {
      data_id: data.debtor_id,
      syncronization_id,
      queueTime: Math.floor(Date.now()),
      uniqueType: `debtor${dUniqueId}`,
      endPoint: "/bgsync/cobro/debtor/update",
      syncType: "updateDebtor",
      syncAmount: "",
      syncTitle: "Perfil de Cliente Actualizado",
      syncName: `Cliente > ${data.name}`,
    };

    dispatch(
      updateDebtorInfo({ dUniqueId, name: data.name, phone: data.phone, nickname: data.nickname, debtorSearch })
    );

    //dispatch(removeNonUniqueType(syncProps));
    // dispatch(createQueue({ postData: data, syncProps }));

    dispatch(updateDebtorNewLoan({ dUniqueId, name: data.name, nickname: data.nickname }));

    updateDebtor({ postData: { ...data, debtorSearch, syncronization_id }, syncProps });

    // if (currentDebtor.new_debtor === true) {
    //   dispatch(updateNewDebtor(data));
    // } else {
    //   dispatch(updateDebtor(data));
    // }

    // dispatch(alertSuccess({ duration: 5000, message: "Cliente actualizado con exito!" }));

    alert.info("Cliente Actualizado!");

    history.push(`/debtor/profile/${dUniqueId}`);
  };

  return (
    <div className="container mt-3 mb-5">
      <RenderDebtorForm
        title="Actualizar Cliente!"
        initialValues={initialValues}
        submithandler={submithandler}
        button={button}
      />

      <div className="mt-3">
        <RenderBackButton />
      </div>
    </div>
  );
};

export default DebtorEdit;
