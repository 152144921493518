import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { printAbsAmount, printAmount, getUniqueID } from "@shared/functions";
// import DatePicker from "@shared/components/DatePicker";
import RenderCuadreData from "@shared/components/RenderCuadreData";
import Loading from "@shared/components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { selectCommon, updateBank } from "../store/slices/common";
import { UseCollected, UseCuadre, UseEntregas, UseGastos, UseRoute } from "@shared/server/fetcher";
import LinearProgressWithLabel from "@shared/components/LinearProgressWithLabel";
import NoInternetContent from "@shared/components/NoInternetContent";
import { createQueue } from "../store/slices/syncronization";
import UpdateBankAmount from "@shared/components/UpdateBankAmountCobro";
import { BsEyeSlashFill } from "react-icons/bs";
import { AiFillBank } from "react-icons/ai";
import { useGetPermissionsQuery, useGetRouteQuery } from "../store/slices/api";
import { useAlert } from "react-alert";

const CentralCuadre = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const common = useSelector(selectCommon);

  const { data, isLoading } = UseCuadre(common.creditor_id, common?.collect_week_date);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const { data: collected, isLoading: paymentLoading } = UseCollected(
    common?.creditor_id,
    data?.current_week,
    data?.range_date
  );

  const { data: entregas, isLoading: entregasLoading } = UseEntregas(
    common?.creditor_id,
    data?.current_week,
    data?.range_date
  );

  const { data: gastos, isLoading: gastosLoading } = UseGastos(
    common?.creditor_id,
    data?.current_week,
    data?.range_date
  );

  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  if (isLoading || !route || !permissions) return <Loading />;
  if (!data) return <NoInternetContent />;

  if (data.cuadre_id === "noCuadreFound")
    return (
      <div className="container m-2">
        <Typography variant="h6">No Cuadre encontrado!</Typography>
      </div>
    );

  const updateBankAmount = (data) => {
    const syncProps = {
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Math.floor(Date.now()),
      uniqueType: "bankUpdate",
      endPoint: "/bgsync/cobro/bank/update",
      syncType: "updateBank",
      syncAmount: data.bankAmount,
      syncTitle: "Caja Banco Actualizado",
      syncName: null,
    };

    //dispatch(removeNonUniqueType(syncProps));
    dispatch(createQueue({ postData: data, syncProps }));
    dispatch(updateBank(data));
    alert.info("Banco Actualizado!");
  };

  const transferedSum = collected?.reduce((acc, obj) => {
    return obj.paymentType !== "cash" ? acc + +obj.paymentAmount : acc;
  }, 0);

  const cashPaymentsSum = collected?.reduce((acc, obj) => {
    return obj.paymentType === "cash" ? acc + +obj.paymentAmount : acc;
  }, 0);

  const totalMoraSum = collected?.reduce((acc, obj) => {
    return acc + +obj.paymentMora;
  }, 0);

  const gastosSum = gastos?.reduce((acc, obj) => {
    return acc + +obj.amount;
  }, 0);

  const actaNoctarialSum = entregas?.reduce((acc, obj) => {
    return acc + +obj.actanotarial;
  }, 0);

  const newLoansSum = entregas?.reduce((acc, obj) => {
    return acc + +obj.amount;
  }, 0);

  const cashMoney = data.bank_amount * 1 + actaNoctarialSum + cashPaymentsSum + totalMoraSum - gastosSum - newLoansSum;

  const progress =
    data.totalCuentaXcobrarAllSum > 0
      ? ((data.totalCuentaXcobrarAllSum - data.totalCuentaXcobrarSum) / data.totalCuentaXcobrarAllSum) * 100
      : 0;

  const handleUnlockCuadre = () => {
    unLockCuadre(history.replace);
  };

  if (!permissions.perm_central)
    return (
      <div className="mt-3 mb-3">
        <Card>
          <CardContent>
            <Box component="div" p={1}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography className="text-danger" variant="h5" component="h4" gutterBottom>
                    No autorizado
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <BsEyeSlashFill onClick={handleUnlockCuadre} size="1.8em" className="text-primary" />
                </Grid>
              </Grid>

              <Typography variant="body1" component="p" paragraph>
                Usted no esta autorizado para ver el cuadre Central.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </div>
    );

  return (
    <>
      <div className="mt-3 mb-3">
        <Box component="div" p={1}>
          <Typography variant="h6" component="h4" className="mb-3">
            Estado de la ruta ({data.cuentaXcobrarAll?.length}): {printAbsAmount(data.totalCuentaXcobrarAllSum)}
          </Typography>

          <Box component="div" paddingBottom={1}>
            <LinearProgressWithLabel value={progress} />
          </Box>

          <Grid container>
            <Grid item xs={10}>
              <Typography variant="body1" component="p">
                (+) Caja / Banco:
                {printAmount(common.bank_amount)}
                <span className="p-1 text-primary">
                  <UpdateBankAmount updateBankAmount={updateBankAmount} common={common} />
                </span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {/* {!permissions.perm_central && (
                <BsEyeSlashFill onClick={handleUnlockCuadre} size="1.4em" className="text-primary" />
              )} */}
            </Grid>
          </Grid>
        </Box>
      </div>

      <RenderCuadreData
        data={data}
        collected={{
          data: collected,
          totalCash: cashPaymentsSum,
          totalMora: totalMoraSum,
          totalDeposit: transferedSum,
          loading: paymentLoading,
        }}
        gastos={{ data: gastos, total: gastosSum, loading: gastosLoading }}
        entregas={{ data: entregas, totalNewLoans: newLoansSum, totalActa: actaNoctarialSum, loading: entregasLoading }}
      />

      <Box component="div" p={1}>
        <Typography variant="body2">
          <span className="text-primary">Capital: {printAbsAmount(data.capitalSum)}</span>
          <span className="text-danger pl-5">Reditos: {printAbsAmount(data.interestSum)}</span>
        </Typography>
      </Box>

      <Card variant="outlined">
        <CardContent>
          <b>
            {cashMoney >= 0 ? (
              <span>( = ) Dinero en Efectivo: {printAbsAmount(cashMoney)}</span>
            ) : (
              <span className="text-danger">( = ) Dinero en Efectivo: {printAbsAmount(cashMoney)} (Negativo)</span>
            )}
          </b>
        </CardContent>
      </Card>

      {transferedSum > 0 && (
        <Card variant="outlined" className="mt-1">
          <CardContent>
            <b>
              <span>
                <AiFillBank size="1.3em" /> Dinero de Transferencia: {printAbsAmount(transferedSum)}
              </span>
            </b>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default CentralCuadre;
