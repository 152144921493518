import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
// import CardContent from "@material-ui/core/CardContent";
// import Divider from "@material-ui/core/Divider";
import useInterval from "@shared/hooks/useInterval";
import axios from "axios";

const LoanApproval = () => {
  const [isUploaded, setUploaded] = useState(false);
  const [isApproved, setApproved] = useState(false);

  useEffect(() => {
    if (isUploaded) return;

    const createLoan = async () => {
      const res = await createLoanApproval(state.temporaryLoanData);
      if (res && res.status === 200) {
        setUploaded(true);
        return;
      }

      createLoan();
    };

    createLoan();
    //eslint-disable-next-line
  }, []);

  useInterval(
    async () => {
      const { creditor_id, debtor_id } = state.temporaryLoanData;
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/loan/approval/${debtor_id}/${creditor_id}`);
      if (data.approved === true) setApproved(true);
    },
    isUploaded && !isApproved ? 1000 * 7 : null
  );

  if (!state.temporaryLoanData) return <Redirect to="/" />;

  return (
    <div className="container mt-4 mb-5">
      <Typography variant="h6" gutterBottom>
        Prestamos debe ser aprobado!
      </Typography>
      <Typography variant="body1" paragraph>
        La cantidad de este prestamo requiere que primero sea aprobado antes de ser entregado!
      </Typography>
    </div>
  );
};

export default LoanApproval;
