import { useEffect, useState } from "react";
import Drawer from "./components/Drawer";
// import WrongCuadreWarning from "./components/WrongCuadreWarning";
import { ToastContainer } from "react-toastify";
// import Snackbar from "@shared/components/Snackbar";
// import StatusAlert from "./components/StatusAlert";
// import SWRFetcher from "./components/SWRFetcher";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
// import ResolveAuth from "./pages/ResolveAuth";
// import RealTimeUpdates from "@shared/pages/RealTimeUpdates";
import Paper from "@material-ui/core/Paper";
import Routes from "./Routes";
import CuadreRoute from "./CuadreRoute";
// import { hot } from "react-hot-loader";
// import { newDate } from "@shared/functions";
// import { differenceInCalendarDays } from "date-fns";
// import { withProfiler } from "@sentry/browser";
// import logger from "./logService";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useSelector } from "react-redux";
// import { selectCommon } from "./store/slices/common";
import { selectAuth } from "./store/slices/auth";
import jwtDecode from "jwt-decode";
// import { selectTheme, setSWSTatus } from "./store/slices/ui";
import { selectTheme } from "./store/slices/ui";
// import { Workbox } from "workbox-window";
// import { useDispatch } from "react-redux";
// import { UseCobroDeltaChanges } from "@shared/server/fetcher";
// import { useEffect } from "react";

const App = () => {
  console.log("App is being mounted.");
  const [forceLogout, setForceLogout] = useState(false);
  // const common = useSelector(selectCommon);
  const auth = useSelector(selectAuth);
  const palette = useSelector(selectTheme);
  // const dispatch = useDispatch();

  // if ("serviceWorker" in navigator && import.meta.env.PROD) {
  //   const wb = new Workbox("sw.js");

  //   wb.addEventListener("installed", (event) => {
  //     if (event.isUpdate) {
  //       dispatch(setSWSTatus({ status: "updated" }));
  //     } else {
  //       dispatch(setSWSTatus({ status: "installed" }));
  //     }
  //   });

  //   wb.register();
  // }

  useEffect(() => {
    if (!auth.jwt) {
      setForceLogout(false);
      return;
    }

    if (jwtDecode(auth.jwt).exp < Date.now() / 1000) {
      setForceLogout(true);
    }
  }, [auth]);

  /**
   *End of Delta changes implementation 
  const dispatch = useDispatch();
  
  const { data: deltaChanges } = UseCobroDeltaChanges(common.creditor_id, common.lastCheckUpdate);
  
  useEffect(() => {
    if (!deltaChanges) return;
    console.log("UseEffect is being triggered", deltaChanges);
    
    async function proccessDeltas() {
      console.log("Here dispatch");
    }
    proccessDeltas();
  }, [deltaChanges]);
  
  *End of Delta changes implementation 
  */

  // const displayWrongCuadreWarning = useMemo(() => {
  //   if (common.collect_date) {
  //     return differenceInCalendarDays(new Date(), newDate(common.collect_date));
  //   }
  //   return 0;
  // }, []);

  const theme = createTheme({ palette });

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {/* <RealTimeUpdates /> */}
        <Drawer />
        <Paper variant="outlined" style={{ minHeight: "100vh" }}>
          {/* <ResolveAuth /> */}
          <ToastContainer />
          {/* <SWRFetcher /> */}
          {/* <Snackbar /> */}
          {/* <StatusAlert /> */}
          {/* {displayWrongCuadreWarning < 4 ? <Routes /> : <CuadreRoute />} */}
          {forceLogout ? <CuadreRoute /> : <Routes />}
        </Paper>
      </ThemeProvider>
    </>
  );
};

// export default hot(module)(withProfiler(App));
// export default hot(module)(App);
export default App;
