import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { browserName, browserVersion } from "react-device-detect";
import MUIDrawer from "@material-ui/core/Drawer";
import MenuList from "@material-ui/core/MenuList";
// import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Alert from "@material-ui/lab/Alert";
import ListItemText from "@material-ui/core/ListItemText";
import { DrawerList } from "./DrawerList";
import { Link } from "react-router-dom";
import InstallPWA from "@shared/hooks/InstallPwa";
// import LinearProgress from "@material-ui/core/LinearProgress";
import StorageQoutaWarning from "@shared/components/StorageQuotaWarning";
import Divider from "@material-ui/core/Divider";
import { FcCalculator } from "react-icons/fc";
import { MdMoreHoriz } from "react-icons/md";
// import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import Calculator from "@shared/components/Calculator";
import { FaRegUser } from "react-icons/fa";
// import { AiOutlineUsergroupAdd, AiOutlineQuestionCircle } from "react-icons/ai";
import { AiOutlineQuestionCircle, AiOutlineUsergroupAdd, AiOutlinePoweroff } from "react-icons/ai";
import { BsCloudArrowUpFill } from "react-icons/bs";
import Menu from "@material-ui/core/Menu";
import { BiLogIn } from "@nodes/react-icons/bi";
import Badge from "@material-ui/core/Badge";
import MoreIcon from "@material-ui/icons/MoreVert";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import InternetConection from "@shared/components/InternetConection";
import Loading from "@shared/components/Loading";
import CustomerBalance from "@shared/components/CustomerBalance";
import BroadcastMessage from "@shared/components/BroadcastMessage";
// import UpdateBackgroundProgrss from "@shared/pages/UpdateBackgroundProgrss";
// import QueuedRequests from "./QueuedRequests";
import ReduxQueueSync from "./ReduxQueueSync";
import DeltaChanges from "../pages/DeltaChanges";
import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";
import RenderTestUserWarning from "@shared/components/RenderTestUserWarning";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/slices/auth";
import { selectCommon } from "../store/slices/common";
import { selectTotalQueues } from "../store/slices/syncronization";
import { useHistory } from "react-router-dom";
import { useGetRouteQuery, useGetUserQuery } from "../store/slices/api";

// import QueueIdbRequests from "./QueueIdbRequests";
// import BackgroundSyncQueue from "./BackgroundSyncQueue";

// import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: 5,
    paddingRight: 10,
    paddingBottom: 10,
  },
  drawer: {
    // width: 250,
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  // title: {
  //   display: "none",
  //   [theme.breakpoints.up("sm")]: {
  //     display: "block",
  //   },
  // },

  selected: {
    backgroundColor: "#7f8691 !important",
    color: "white",
    fontWeight: 600,
  },

  title2: {
    flexGrow: 1,
  },
}));

export default function Drawer() {
  const history = useHistory();
  const totalQueues = useSelector(selectTotalQueues);
  const auth = useSelector(selectAuth);
  const common = useSelector(selectCommon);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const [isSupportedBgSync, setSupportedBgSync] = useState(true);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const skip = auth.jwt && common.user_id && common.creditor_id ? false : true;

  const { data: route } = useGetRouteQuery(
    { creditor_id: common.creditor_id, user_id: common.user_id, collect_date: common.collect_date },
    { skip }
  );
  const { data: user } = useGetUserQuery(common.user_id, { skip });

  useEffect(() => {
    // if ("serviceWorker" in navigator && "SyncManager" in window) {
    //   // service worker stuffs here
    //   setSupportedBgSync(true);
    // } else {
    //   setSupportedBgSync(false);
    //   console.error("Background sync not supported");
    // }
    // if (navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("message", (event) => {
    //     if (event.data.type === "BGSYNC_QUEUE_SIZE_COUNT") {
    //       dispatchAction("setBgSyncQueueSize", { bgSyncQueueSize: event.data.bgSyncQueueSize });
    //     }
    //     if (event.data.type === "BGSYNC_SUCCESS_UPDATE") {
    //       dispatchAction("updateSyncSuccess", { syncronization_id: event.data.syncronization_id });
    //     }
    //   });
    //   navigator.serviceWorker.ready.then((registration) => {
    //     registration.active.postMessage({ type: "getBgSyncQueueSize" });
    //   });
    // }
    //eslint-disable-next-line
  }, []);

  // if (!user || !route) return <Loading />;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileNavigation = (to) => {
    handleMobileMenuClose();
    history.push(to);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  //This needs to come from the server.
  const allowedBrowsers = {
    Chrome: { version: 102 },
    "Samsung Browser": { version: 16 },
    Opera: { version: 60 },
  };

  // const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {!auth.jwt && (
        <MenuItem onClick={() => handleMobileNavigation("/login")}>
          <IconButton aria-label="show 0 new notifications" color="inherit">
            <BiLogIn />
          </IconButton>
          Iniciar Sesion
        </MenuItem>
      )}

      <MenuItem onClick={() => handleMobileNavigation("/quickprinter")}>
        <IconButton aria-label="show 0 new notifications" color="inherit">
          <BluetoothIcon />
        </IconButton>
        Conectar Impresora
      </MenuItem>

      {auth.jwt && (
        <>
          <MenuItem onClick={() => handleMobileNavigation("/referrals")}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit">
              <AiOutlineUsergroupAdd size="1.2em" />
            </IconButton>
            Referir un amigo
          </MenuItem>

          <MenuItem onClick={() => handleMobileNavigation("/profile")}>
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit">
              <FaRegUser size="0.8em" />
            </IconButton>
            Mi Perfil
          </MenuItem>

          <Divider />

          <MenuItem className="text-danger" onClick={() => handleMobileNavigation("/logout")}>
            <IconButton
              aria-label="logout"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit">
              <AiOutlinePoweroff size="0.8em" />
            </IconButton>
            Salir
          </MenuItem>

          <Divider />
        </>
      )}

      <MenuItem onClick={() => handleMobileNavigation("/contact")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <AiOutlineQuestionCircle />
        </IconButton>
        Contactarme
      </MenuItem>

      <MenuItem onClick={() => handleMobileNavigation("/more/tools")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <MdMoreHoriz size="1.3em" />
        </IconButton>
        Mas!
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <InternetConection />

      <AppBar style={{ marginLeft: -1, marginTop: -1 }} position="static" color="secondary">
        {auth.jwt ? (
          <Toolbar>
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer">
              <MenuIcon />
            </IconButton>
            <Typography onClick={() => history.push("/dashboard")} className={classes.title} variant="h6" noWrap>
              WPrestamos
            </Typography>

            <div className={classes.grow} />
            <div>
              <IconButton aria-haspopup="true" onClick={() => history.push("/syncronization")} color="inherit">
                <Badge overlap="rectangular" badgeContent={+totalQueues} color="primary">
                  <BsCloudArrowUpFill size="1.1em" />
                </Badge>
              </IconButton>
            </div>
            <div>
              <Calculator>
                <IconButton aria-haspopup="true">
                  <FcCalculator />
                </IconButton>
              </Calculator>
              {/* <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/profile")}
                color="inherit">
                <FaRegUser size="0.9em" />
              </IconButton> */}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        ) : (
          <Toolbar>
            <Typography onClick={() => history.push("/")} className={classes.title2} variant="h6" noWrap>
              Bienvenido
            </Typography>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
            {+totalQueues > 0 && (
              <div>
                <IconButton aria-haspopup="true" onClick={() => history.push("/syncronization")} color="inherit">
                  <Badge overlap="rectangular" badgeContent={+totalQueues} color="primary">
                    <BsCloudArrowUpFill size="1.1em" />
                  </Badge>
                </IconButton>
              </div>
            )}
          </Toolbar>
        )}
      </AppBar>

      {auth.jwt && (
        <>
          <CustomerBalance history={history} owner_id={route?.user_id} mainOnly={0} />
          <DeltaChanges />
          <RenderTestUserWarning route={route} />
        </>
      )}

      <BroadcastMessage />

      <DisplayAppEnvName avoidProd={true} displayLabel={false} />

      {/* <BackgroundSyncQueue syncCount={82} /> */}
      {/* <QueuedRequests /> */}

      {+totalQueues > 0 && <ReduxQueueSync />}

      {/* This comment makes the app feeeze on some devices and it takes long to create a payment. */}
      {/* <QueueIdbRequests dispatchAction={dispatchAction} /> */}
      {/* <UpdateBackgroundProgrss dispatchAction={dispatchAction} showSyncProgress={showSyncProgress} /> */}

      <MUIDrawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <div className="text-center">
            <img
              alt="app-logo"
              style={{ width: 165, height: 93, marginBottom: 20, marginTop: 15 }}
              src="/../../logo.png"
            />
          </div>

          {!user || !route ? (
            <Loading />
          ) : (
            <span>
              <Typography variant="body2">{route.route_name}</Typography>
              <Typography variant="body1">Zona: {route.location}</Typography>
              <Typography variant="body1">Cobrador: {user.fname}</Typography>
            </span>
          )}
        </div>
        <Divider />
        <MenuList>
          <div onClick={() => toggleDrawer()}>
            {DrawerList.map((item, index) => {
              return (
                <div key={index}>
                  <MenuItem
                    component={Link}
                    to={item.path}
                    classes={{ selected: classes.selected }}
                    selected={history.location.pathname === item.path}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuList>
      </MUIDrawer>

      {renderMobileMenu}
      <InstallPWA />
      <StorageQoutaWarning />

      {/* {isSupportedBgSync === false && (
        <Container className="p-2">
          <Alert variant="outlined" severity="error">
            <Typography variant="h6" component="p">
              <b> Atencion!</b>
            </Typography>
            <Typography variant="body1" component="p">
              Este navegador no es compactible con la aplicacion!
            </Typography>
          </Alert>
        </Container>
      )} */}

      {/* {(!allowedBrowsers[browserName] || !isAndroid) && import.meta.env.PROD  && (
        <Container className="p-2">
          <Alert variant="outlined" severity="error">
            <Typography variant="subtitle1" component="p">
              Esta aplicacion web solo funciona en los siguientes navegadores: <b>Chrome</b>, <b>Opera</b> y{" "}
              <b>Samsung</b> Y unicamente en telefonos <b>Android</b>!
            </Typography>
          </Alert>
        </Container>
      )} */}

      {allowedBrowsers[browserName] &&
        browserVersion < allowedBrowsers[browserName].version &&
        import.meta.env.PROD && (
          <Container className="p-2">
            <Alert variant="outlined" severity="warning">
              <Typography variant="subtitle1" component="p">
                <b>Por favor, actualice su navegador {browserName}!</b>
              </Typography>
            </Alert>
          </Container>
        )}
    </div>
  );
}
