import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Loading from "@shared/components/Loading";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
// import Button from "@material-ui/core/Button";
// import Box from "@material-ui/core/Box";
// import { dexieSyncDB } from "@shared/context/dexieDB";
// import { useLiveQuery } from "dexie-react-hooks";
// import { printAmount } from "@shared/functions";
// import TemporarilyDisabled from "@shared/pages/TemporarilyDisabled";
import { FcCompactCamera } from "react-icons/fc";
import { useHistory, useParams } from "react-router-dom";

const CameraPermission = () => {
  const history = useHistory();
  const { id: money_id } = useParams();
  const [isLoading, setLoading] = useState(true);

  const localStorageName = "cameraPermissionGranted";

  useEffect(() => {
    if (localStorage.getItem(localStorageName)) {
      history.replace(`/renewal/upload/${money_id}`);
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) return <Loading />;

  const handleNavigate = () => {
    localStorage.setItem(localStorageName, true);
    history.replace(`/renewal/upload/${money_id}`);
  };

  return (
    <div className="container text-center mt-5">
      <div className="mt-3 mb-4 text-center">
        <FcCompactCamera size="8em" />
      </div>

      <Typography variant="h6" paragraph>
        Permiso camara requerido!
      </Typography>

      <Typography variant="body1">
        En la siguiente pagina debes aceptar el permiso pedido, por favor presione la opcion adecuada para obturgar el
        permiso de camara.
      </Typography>

      <Box component={"div"} paddingTop={4} p={1}>
        <Divider />
      </Box>

      <Box component={"div"} paddingTop={3} p={1}>
        <Button fullWidth onClick={handleNavigate} variant="text" color="primary" size="small">
          Continuar!
        </Button>
      </Box>
    </div>
  );
};

export default CameraPermission;
