import { useState, useEffect } from "react";
import { object } from "yup";
import { useHistory } from "react-router-dom";
import { Camera } from "./camera";
import { Form } from "@shared/components/Form";
import { Root, Preview, Footer, GlobalStyle } from "./styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { MdOutlineVerified } from "react-icons/md";
import Loading from "@shared/components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { createNewLoan } from "../../store/slices/newLoans";
import { createQueue } from "../../store/slices/syncronization";
import {
  useGetOwnerConfigQuery,
  useGetRouteQuery,
  useGetUserConfigQuery,
  useGetUserQuery,
} from "../../store/slices/api";
import { selectCommon } from "../../store/slices/common";
import { getUniqueID } from "@shared/functions";
import { printEntregaHandler, printLoanContractHandler, printReceiptHandler } from "@shared/functions/print";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { createPayment } from "../../store/slices/payments";
import { addDebtorsReordered } from "../../store/slices/loans";
import { useAlert } from "react-alert";

const CameraPermissionDenied = () => {
  return (
    <div className="mt-5 mb-5">
      <Card raised>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Camara aceso negado!
          </Typography>

          <Typography variant="body1" paragraph>
            El aceso a camera fue negado
          </Typography>

          <Typography variant="body1" paragraph>
            Para continuar con este prestamo, tiene que darle aceso a la camara o desabilar la opcion de requerir foto
            para nuevo prestamo.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

const CameraComponent = () => {
  const history = useHistory();
  const alert = useAlert();
  const [cameraPermission, setCameraPermission] = useState(true);

  navigator.getUserMedia(
    {
      video: true,
      // audio: true,
    },

    // successCallback
    function () {},

    // errorCallback
    function (err) {
      if (err.message === "Permission denied") {
        setCameraPermission(false);
      }
    }
  );

  const dispatch = useDispatch();

  let newData = localStorage.getItem("newData");
  let syncProps = localStorage.getItem("syncProps");

  newData = newData ? JSON.parse(newData) : null;
  syncProps = syncProps ? JSON.parse(syncProps) : null;

  const currentLoan = newData.loan;
  const currentDebtor = newData?.debtor;

  const common = useSelector(selectCommon);

  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: user } = useGetUserQuery(common.user_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState();
  const [dataImage, setDataImage] = useState(null);

  useEffect(() => {
    setIsCameraOpen(true);
  }, []);

  if (!newData || !syncProps) return <Redirect to="/dashboard" />;
  if (cameraPermission === false) return <CameraPermissionDenied />;

  if (!ownerConfig || !userConfig || !user || !route) return <Loading />;

  const initialValues = currentLoan;
  const submithandler = async (data) => {
    const { user_id, creditor_id, debtor_id, money_id, mUniqueId } = data;

    let recData = localStorage.getItem("recData");
    recData = recData ? JSON.parse(recData) : null;

    if (recData) {
      dispatch(createQueue(recData.sync));
      dispatch(createPayment(recData.collected));
      // dispatch(applyPayment(recData.applyLoan));
      alert.success("Pago Aplicado!", { timeout: 3500, position: "top center" });

      if (ownerConfig.autoPrintPayment === "1") {
        printReceiptHandler(
          { ...currentLoan, ...recData.collected },
          { ...userConfig, ...route, ...ownerConfig, ...common }
        );
      }

      localStorage.removeItem("recData");
    }

    if (currentDebtor?.new_debtor) {
      dispatch(createQueue({ postData: { debtor: currentDebtor, loan: newData }, syncProps }));
    } else {
      dispatch(createQueue({ postData: newData, syncProps }));
    }

    dispatch(createNewLoan(newData));
    dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

    localStorage.removeItem("syncProps");
    localStorage.removeItem("newData");

    const imgSyncProps = {
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Math.floor(Date.now()),
      endPoint: "/bgsync/cobro/loan/identification/upload",
      syncType: "uploadRenewalPicture",
      syncAmount: data.amount,
      syncTitle: "Foto de Nuevo Prestamo",
      syncName: `Cliente > ${data.name}`,
    };

    dispatch(
      createQueue({
        postData: { user_id, creditor_id, debtor_id, money_id, mUniqueId, dataImage },
        syncProps: imgSyncProps,
      })
    );

    alert.success("Prestamo Guardado!", { position: "top center" });
    alert.success("Foto Guardada!", { position: "top center" });

    if (ownerConfig.digitalContract === "1") {
      history.replace(`/loan/signature/${currentLoan.money_id}`);
    } else {
      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(data, { ...route, ...common }, { ...userConfig, ...user, ...userConfig });
      }
      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(data, { ...route, ...common }, { ...userConfig, ...user, ...userConfig });
      }

      history.replace("/entregas");
    }
  };

  const validation = object({});
  let button = { label: "Guardar Prestamo", variant: "contained", color: "primary" };
  if (ownerConfig.digitalContract === "1") {
    button = {
      icon: <MdOutlineVerified />,
      label: "Continuar",
      color: "primary",
      variant: "outlined",
    };
  }

  return (
    <>
      <Root>
        {isCameraOpen && (
          <Camera
            onCapture={(blob) => {
              setCardImage(blob);
              setIsCameraOpen(false);
            }}
            setDataImage={setDataImage}
            onClear={() => setCardImage(undefined)}
          />
        )}

        {cardImage && (
          <div>
            <Typography variant="h5"> Vista Previa</Typography>
            <Preview src={cardImage && URL.createObjectURL(cardImage)} />
            <Box paddingTop={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  setCardImage(undefined);
                  setIsCameraOpen(true);
                  setDataImage(undefined);
                }}>
                Capturar otra Foto
              </Button>
            </Box>
          </div>
        )}

        {dataImage && (
          <Box paddingTop={3} component="div">
            <Form
              initialValues={initialValues}
              validation={validation}
              submithandler={submithandler}
              button={button}></Form>
          </Box>
        )}

        <Footer>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setCardImage(undefined);
              setIsCameraOpen(true);
            }}>
            Abrir Camara
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setIsCameraOpen(false);
              setCardImage(undefined);
              setDataImage(undefined);
            }}>
            Cerrar Camara
          </Button>
        </Footer>
      </Root>
      <GlobalStyle />
    </>
  );
};

export default CameraComponent;
