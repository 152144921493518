import CreateSpense from "../components/CreateSpense";
// import UpdateBankAmount from "@shared/components/UpdateBankAmountCobro";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import DeleteGasto from "@shared/components/DeleteGasto";
import { printAmount, getUniqueID } from "@shared/functions";
import { useSelector, useDispatch } from "react-redux";
import { selectGastos, createGasto, deleteGasto, selectTotalGastos } from "../store/slices/gastos";
import { selectCommon } from "../store/slices/common";
import { createQueue } from "../store/slices/syncronization";
import { getTimestamp } from "@shared/functions";
// import { alertInfo, alertSuccess, alertWarning } from "../store/slices/snackbar";
import { useAlert } from "react-alert";

const Spenses = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const gastos = useSelector(selectGastos);
  const common = useSelector(selectCommon);
  const totalGastos = useSelector(selectTotalGastos);

  const handleCreateGasto = (data) => {
    const timestamp = getTimestamp();
    const gUniqueId = timestamp + common.creditor_id;

    const syncProps = {
      data_id: gUniqueId,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Math.floor(Date.now()),
      endPoint: "/bgsync/cobro/gasto/create",
      syncType: "createGasto",
      syncAmount: data.amount,
      syncTitle: "Gasto Registrado",
      syncName: `Descripcion > ${data.description}`,
    };

    const newData = { ...data, gUniqueId, timestamp };

    dispatch(createQueue({ postData: newData, syncProps }));
    dispatch(createGasto(newData));
    // dispatch(createGasto({ postData: newData, syncProps }));
    // dispatch(alertSuccess({ duration: 5000, message: "Gasto registrado con exito!" }));
    alert.success("Gasto Registrado!", { position: "bottom center" });
  };

  const handleDeleteGasto = async (data) => {
    const syncProps = {
      data_id: data.gUniqueId,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Math.floor(Date.now()),
      endPoint: "/bgsync/cobro/gasto/delete",
      syncType: "deleteGasto",
      syncAmount: data.amount,
      syncTitle: "Gasto Borrado",
      syncName: `Descripcion > ${data.description}`,
    };

    dispatch(createQueue({ postData: data, syncProps }));
    dispatch(deleteGasto(data));
    // dispatch(alertWarning({ duration: 5000, message: "Gasto borrado con exito!" }));
    alert.error("Gasto Borrado!");
  };

  // const updateBankAmount = (data) => {
  //   const syncProps = {
  //     syncronization_id : `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
  //     queueTime: Math.floor(Date.now()),
  //     uniqueType: "bankUpdate",
  //     endPoint: "/bgsync/cobro/bank/update",
  //     syncType: "updateBank",
  //     syncAmount: data.bankAmount,
  //     syncTitle: "Caja Banco Actualizado",
  //     syncName: null,
  //   };

  //   dispatch(removeNonUniqueType(syncProps));
  //   dispatch(createQueue({ postData: data, syncProps }));
  //   dispatch(updateBank(data));
  //   // dispatch(alertInfo({ duration: 5000, message: "Banco de caja actualizado!" }));

  //   alert.info("Banco Actualizado!");
  // };

  return (
    <div className="container mt-3 mb-5">
      <CreateSpense handleCreateGasto={handleCreateGasto} />

      <Typography variant="h6" component="h4" gutterBottom>
        Gastos Registrado {printAmount(totalGastos)}
      </Typography>

      {/* <Typography variant="h6" component="h4" gutterBottom>
        Caja / Banco:
        <NumberFormat value={common.bank_amount} displayType={"text"} thousandSeparator={true} prefix={" $"} />
        <span className="p-1 text-primary">
          <UpdateBankAmount updateBankAmount={updateBankAmount} common={common} />
        </span>
      </Typography> */}

      {gastos.length === 0 ? (
        <div className="mt-3">
          <Typography variant="subtitle1" component="p" paragraph>
            En esta pagina puede ver todo los gastos aplicado.{" "}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom>
            Por el momento, no se han agregado ningun gasto!
          </Typography>
        </div>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              {/* <th scope="col"></th> */}
              <th scope="col">#</th>
              <th scope="col">Nota</th>
              <th scope="col">Cantidad</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {gastos.map((gasto, index) => (
              <tr key={index}>
                {/* <td>
                  {gasto.synced === true ? (
                    <MdOutlineCloudDone className="text-success" />
                  ) : (
                    <MdOutlineCloudOff className="text-danger" />
                  )}
                </td> */}
                <td className="font-italic">{index + 1}</td>
                <td className="font-italic">{gasto.description}</td>
                <td className="text-danger">
                  {<NumberFormat value={gasto.amount} displayType={"text"} thousandSeparator={true} prefix={" $"} />}
                </td>
                <td>
                  <DeleteGasto deleteGasto={handleDeleteGasto} gasto={gasto} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Spenses;
