import { string, object } from "@nodes/yup";
import { Form, RenderInput } from "@shared/components/Form";
import Box from "@nodes/@material-ui/core/Box";
import Card from "@nodes/@material-ui/core/Card";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Grid from "@nodes/@material-ui/core/Grid";
import Typography from "@nodes/@material-ui/core/Typography";
import backend from "@shared/server/backend";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pwResetLookup } from "../store/slices/tempState";
import Axios from "axios";
import { getLocalToken } from "@shared/functions";

const passwordResetLookup = async (data) => {
  try {
    let token = getLocalToken();

    const client = await Axios.get("https://ipapi.co/json/");
    data.ipAddress = client.data.ip;
    data.token = token;

    return await backend.post("/username/lookup", { data });
  } catch (err) {
    console.log("AppWorkflow - uploadCuadre from dataContext - Catch error");
    console.log(err.message);
  }
};

const UserLookup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    phone: "",
    cedula: "",
  };

  const validation = object({
    name: string().required("Nombre es requerido!"),
    cedula: string().required("Numero de Cedula es requerido!"),
  });

  const validation2 = object({
    name: string().required("Nombre es requerido!"),
    phone: string().required("Numero de telefono es requerido!"),
  });

  const button = { label: "Continuar", variant: "contained", color: "primary" };

  const submithandler = async (data) => {
    try {
      const response = await passwordResetLookup(data);
      dispatch(pwResetLookup(response.data));
      history.replace("/password/resetmethod");
    } catch (error) {}
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Restablecer Contraña!
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Ingrese las siguiente informacion y le ayudare a establecer una nueva contraña.
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="1. Busqueda de Cedula" />
            <CardContent>
              <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
                <Box paddingBottom={3}>{RenderInput("name", "Nombre Completo")}</Box>
                <Box paddingBottom={5}>{RenderInput("cedula", "Numero de Cedula", "number")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="2. Busqueda de Numero" />
            <CardContent>
              <Form
                initialValues={initialValues}
                validation={validation2}
                submithandler={submithandler}
                button={button}>
                <Box paddingBottom={3}>{RenderInput("name", "Nombre Completo")}</Box>
                <Box paddingBottom={5}>{RenderInput("phone", "Numero de Telefono", "tel")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserLookup;
