import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";
// import Loading from "@shared/components/Loading";
import { Link } from "react-router-dom";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography ";

const MoreTools = () => {
  return (
    <div className="container mt-4 mb-5">
      <DisplayAppEnvName />

      <div className="text-center mt-5 mb-3">
        <Link to="/upload/app/state" className="h6">
          1. Subir Estado de la aplicacion
        </Link>
      </div>

      <div className="text-center mt-4 mb-3">
        <Link to="/swr/fetcher" className="h6">
          2. Verificar Datos Descargado
        </Link>
      </div>

      <div className="text-center mt-4 mb-3">
        <Link to="/clear/app/data" className="h6">
          <span className="text-danger">3. Reiniciar Aplicaci&oacute;n (Borrar Todo)</span>
        </Link>
      </div>
    </div>
  );
};

export default MoreTools;
