import { useState } from "react";
import SearchBox from "@shared/components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";
// import CollectedPaymentMenu from "../components/CollectedPaymentMenu";
// import { Link } from "react-router-dom";
import { printAmount, RemoveAccents } from "@shared/functions";
import BottomNavigation from "../components/BottomNavigation";
import LoadingInspect from "@shared/components/LoadingInspect";
// import { printReceiptHandler } from "@shared/functions/print";
import useDebounce from "@shared/hooks/useDebounce";
import { useSelector } from "react-redux";
import { selectCommon } from "../store/slices/common";
// import { selectLoans } from "../store/slices/loans";
import { selectTotalNewLoans } from "../store/slices/newLoans";
import { selectCollected, selectTotalCollected } from "../store/slices/payments";
import { useHistory } from "react-router-dom";
import {
  useGetOwnerConfigQuery,
  useGetUserConfigQuery,
  useGetPermissionsQuery,
  useGetRouteQuery,
} from "../store/slices/api";
import { CiReceipt } from "@nodes/react-icons/ci";
// import { AiFillBank } from "@nodes/react-icons/ai";

const getDisplayData = (data, searchText, limit) => {
  if (!data) [];

  if (searchText.length < 3) {
    return data.slice(0, limit);
  } else {
    return data.filter((x) => RemoveAccents(x.name).toLowerCase().indexOf(searchText.toLowerCase()) > -1).slice(0, 10);
  }
};

const Collected = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // const loans = useSelector(selectLoans);
  const common = useSelector(selectCommon);
  const totalLoan = useSelector(selectTotalNewLoans);
  const collected = useSelector(selectCollected);
  const totalCollected = useSelector(selectTotalCollected);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);
  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  useDebounce(
    () => {
      setFilteredData(getDisplayData(collected, searchText, 50));
    },
    [searchText],
    searchText.length < 3 ? 0 : 800
  );

  if (!ownerConfig || !permissions || !route || !userConfig)
    return (
      <LoadingInspect
        data={[
          {
            loaded: ownerConfig && Object.keys(ownerConfig).length > 0,
            label: "OwnerConfig",
          },
          {
            loaded: permissions && Object.keys(permissions).length > 0,
            label: "Permissions",
          },
          {
            loaded: route && Object.keys(route).length > 0,
            label: "Route",
          },
          {
            loaded: userConfig && Object.keys(userConfig).length > 0,
            label: "User Config",
          },
        ]}
      />
    );

  // const handlePaymentPrint = (payment, option) => {
  //   const loan = loans.find((x) => x.money_id === payment.money_id);

  //   if (option.printDestination === "whatsapp") {
  //     window.location = `whatsapp://send?text=${printReceiptHandler(
  //       { ...loan, ...payment },
  //       {
  //         ...route,
  //         ...ownerConfig,
  //         ...common,
  //         printApp: "whatsapp",
  //       }
  //     )}`;
  //   } else {
  //     printReceiptHandler({ ...loan, ...payment }, { ...route, ...ownerConfig, ...userConfig, ...common });
  //   }
  // };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <>
      {totalCollected.collected === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Prestamos Cobrado!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los pagos aplicado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no ha aplicado ningun Pago!
          </Typography>
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              {permissions.perm_efectivo && +common.owner_id !== 2593 ? (
                <span>
                  Prestamos cobrado ({totalCollected.count}):{" "}
                  {printAmount(totalCollected.collectedCash + totalLoan.adelanto)}
                </span>
              ) : (
                <span>Prestamos cobrado ({totalCollected.count})</span>
              )}
            </Typography>

            <Typography variant="body1" component="h4">
              Incluyendo {printAmount(totalLoan.adelanto)} adelanto de entrega!
            </Typography>

            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  {/* <th scope="col"></th> */}
                  <th scope="col">Nombre</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((payment) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(`/payment/receipt/${payment.payment_id}/${payment.syncronization_id}`)}
                    key={payment.payment_id}
                    className={payment.paymentType === "cash" ? "" : "bg-warning"}>
                    {/* <td>
                      {payment.synced === true ? (
                        <MdOutlineCloudDone className="text-success" />
                      ) : (
                        <MdOutlineCloudOff className="text-danger" />
                      )}
                    </td> */}
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        {/* <Link style={{ color: "#000" }} to={`/payment/receipt/${payment.payment_id}`}> */}
                        <PersonIcon /> {payment.name}
                        {/* </Link> */}
                      </Typography>
                    </td>
                    <td>
                      {/* {payment.paymentType === "cash" ? (
                        <AiFillBank size="1.3em" />
                      ) : (
                        <FaRegMoneyBillAlt size="1.3em" />
                      )} */}

                      {/* <span className="text-primary">{printAmount(payment.paymentAmount)}</span> */}

                      {+common.owner_id === 2593 ? (
                        <span className="pl-1">* * * *</span>
                      ) : (
                        <span className="pl-1">{printAmount(payment.paymentAmount)}</span>
                      )}
                      {/* <span className="pl-1">{printAmount(payment.paymentAmount)}</span> */}
                    </td>
                    <td>
                      <CiReceipt size="1.6em" />
                      {/* <CollectedPaymentMenu handlePaymentPrint={handlePaymentPrint} payment={payment} /> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <BottomNavigation />
    </>
  );
};

export default Collected;
