// import { useEffect } from "react";
// import { Context } from "@shared/context/DataContext";
// import Loading from "@shared/components/Loading";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
// import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import Box from "@material-ui/core/Box";
// import { dexieSyncDB } from "@shared/context/dexieDB";
// import { useLiveQuery } from "dexie-react-hooks";
// import { printAmount } from "@shared/functions";
// import TemporarilyDisabled from "@shared/pages/TemporarilyDisabled";
import { useSelector } from "react-redux";
import { selectQueues, selectTotalQueues } from "../store/slices/syncronization";
import { printAmount } from "@shared/functions";
import { BsCloudCheck } from "react-icons/bs";

const Syncronization = () => {
  const queues = useSelector(selectQueues);
  const queuesTotal = useSelector(selectTotalQueues);

  if (queuesTotal === 0)
    return (
      <div className="container text-center mt-5">
        <div className="mt-5 mb-4 text-center text-success">
          <BsCloudCheck size="8em" />
        </div>

        <Typography variant="h6" gutterBottom>
          No hay datos pendiente!
        </Typography>
        <Typography variant="body1">
          Por el momento no hay ningun dato pendiente, todo los datos han sido guardado en la app central de manera
          segura.
        </Typography>
      </div>
    );

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h6" gutterBottom>
        Los siguiente datos ahun no han sido guardado en la app Central.
      </Typography>
      <div style={{ maxHeight: 500, width: "100%", overflow: "scroll" }}>
        {queues.map((x) => (
          <Box key={x.syncronization_id} component={"div"} p={1}>
            <Typography variant="h6" className="pl-2" gutterBottom>
              {x.syncTitle} {x.syncAmount && printAmount(x.syncAmount)}
            </Typography>

            {x.syncName && (
              <Typography variant="body1" className="pl-2" gutterBottom>
                {x.syncName}
              </Typography>
            )}

            {x.error && (
              <Typography variant="body2" className="pl-2 text-danger" gutterBottom>
                No. de Intentos {x.triesCount} | {x.error}
              </Typography>
            )}

            <Divider />
          </Box>
        ))}
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="text-center mt-5">
        <Link to="/upload/app/sync">Subir Estado de Syncronizacion!</Link>
      </div>
    </div>
  );
};

export default Syncronization;
