import { useState, useEffect } from "react";
import { Redirect, useHistory } from "@nodes/react-router-dom";
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import FormGroup from "@nodes/@material-ui/core/FormGroup";
import FormControlLabel from "@nodes/@material-ui/core/FormControlLabel";
import Switch from "@nodes/@material-ui/core/Switch";
import Button from "@nodes/@material-ui/core/Button";
import Divider from "@nodes/@material-ui/core/Divider";
import { useSelector } from "react-redux";
import { selectUserLookup } from "../store/slices/tempState";

const PasswordResetMethod = () => {
  const history = useHistory();

  const passwordReset = useSelector(selectUserLookup);

  const [resetMethod, setResetMethod] = useState("sms");

  useEffect(() => {
    if (passwordReset) {
      if (passwordReset.verified === "0") {
        setResetMethod("questions");
      }
    }
  }, [passwordReset]);

  if (
    Object.keys(passwordReset).length === 0 ||
    (passwordReset.questionSet === "0" && passwordReset.verified === "0")
  ) {
    return <Redirect to="/" />;
  }

  const handleMethodChange = (e) => {
    setResetMethod(e.target.value);
  };

  const navigateUser = () => {
    const redirect = resetMethod === "sms" ? "/password/phone/verify" : "/security/verify";
    history.replace(redirect);
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Restablecer Contraña!
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Por su seguridad, selecione un metodo para verificar su cuenta.
      </Typography>

      <Divider className="mb-3" />

      <FormGroup row>
        <Box paddingBottom={2}>
          <FormControlLabel
            control={
              <Switch
                disabled={passwordReset.verified === "0" ? true : false}
                checked={resetMethod === "sms" ? true : false}
                onChange={handleMethodChange}
                name={"resetMethod"}
                value={"sms"}
              />
            }
            label="Mensaje de Texto - Enviar mensaje de Texto a mi telefono."
          />
          {passwordReset.verified === "0" && <p className="text-danger">Esta opcion no esta disnoblie!</p>}
        </Box>
        <Box paddingBottom={0}>
          <FormControlLabel
            control={
              <Switch
                disabled={passwordReset.questionSet === "0" ? true : false}
                checked={resetMethod !== "sms" ? true : false}
                onChange={handleMethodChange}
                name={"resetMethod"}
                value={"questions"}
              />
            }
            label="Preguntas de Seguridad - Contestar mi pregunta de Seguridad."
          />
          {passwordReset.questionSet === "0" && <p className="text-danger">Esta opcion no esta disnoblie!</p>}
        </Box>
      </FormGroup>

      <Box paddingTop={4}>
        <Button fullWidth variant="outlined" color="primary" onClick={navigateUser}>
          Continuar
        </Button>
      </Box>

      <br />
    </div>
  );
};

export default PasswordResetMethod;
