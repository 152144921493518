import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, RenderInput } from "@shared/components/Form";
import { number, object } from "yup";
import { deleteCache } from "@shared/functions";
import { localStorageReduxPersist, localStorageRememberUser } from "@/config";
import { verifyAccessCode } from "@shared/api";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useEffect } from "react";
import { unregister } from "../registerServiceWorker";

function refreshPage() {
  const interval = setTimeout(() => {
    window.location.reload(false);
  }, 2000);

  return () => clearInterval(interval);
}

const ClearAppData = () => {
  const alert = useAlert();
  const { reloaded } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (reloaded === "reloaded") {
      history.replace("/login");
    }
  }, []);

  const initialValues = {
    accessCode: "",
    owner_id: 1001,
    type: "clearApp",
  };

  const validation = object({
    accessCode: number().required("Codigo es requerida!"),
  });

  const button = { label: "Verificar Codigo", color: "primary", variant: "contained" };
  const submithandler = async (data) => {
    // const url = window.location.origin;
    // const browserCaches = await getCachedData("cobro-caches", url);

    try {
      await verifyAccessCode(data);

      const localStorageName = "cameraPermissionGranted";
      localStorage.removeItem(localStorageName);
      localStorage.removeItem(localStorageRememberUser);
      localStorage.removeItem(`persist:${localStorageReduxPersist}`);
      unregister();
      deleteCache();

      alert.info("Datos de App borrado!");

      history.push("/clear/app/data/reloaded");

      refreshPage();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-5">
      <Typography variant="h6" paragraph>
        Reiniciar aplicacion y borrar todo!
      </Typography>

      <Typography variant="body1" paragraph>
        Para ejercer la siguiente accion necesita un codigo de autorizacion
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>
          {RenderInput(
            "accessCode",
            "Codigo de Aceso",
            "number",
            false,
            "outlined",
            "Ingrese el codigo de autorizacion"
          )}
        </Box>
      </Form>

      <Typography className="mt-2" variant="body2" paragraph>
        <b>Cada codigo solo puede ser usado una vez!</b>
      </Typography>
    </div>
  );
};

export default ClearAppData;
