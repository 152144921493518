import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { fetchCommonThunk, selectCommonError, selectCommonStatus } from "../store/slices/common";
import { selectAuth } from "../store/slices/auth";

const InitializeCommon = () => {
  const dispatch = useDispatch();

  const auth = useSelector(selectAuth);
  const status = useSelector(selectCommonStatus);
  const error = useSelector(selectCommonError);

  useEffect(() => {
    if (status === "idle") dispatch(fetchCommonThunk(auth));
  }, [status, dispatch]);

  if (error)
    return (
      <div className="container mt-5 mb-5">
        <Typography variant="h6" component="p">
          Ha ocurrido un error!
        </Typography>

        <div className="alert alert-danger mt-3">
          <Typography variant="subtitle2" component="p">
            {error}
          </Typography>
        </div>
      </div>
    );

  return status !== "succceed" ? <FullLoadingScreen /> : <Redirect to="/swr/fetcher" />;
};

export default InitializeCommon;
